<template>
  <div style="flex: 1">
    <div id="main2" style="width: 623px; height: 400px"></div>
  </div>
</template>
<script>
export default {
  name: "Echarts2",
  methods: {
    myEcharts() {
      var myChart = this.$echarts.init(document.getElementById("main2"));
      //配置图表
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        graphic: {
          type: "text",
          left: "center",
          top: "center",
          style: {
            text:"累计\n2134万亩",
            textAlign: "center",
            fill: "#000",
            width: 30,
            height: 30,
            fontSize: 22
          }
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
            ],
            sum: {
              name: ["总计"],
              number: 0,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    this.myEcharts();
  },
};
</script>
