<template>
  <div>
    <Top></Top>
    <div class="grass_box">
      <div class="grass_box_title"></div>
      <div class="grass_box_con">
        <el-tabs v-model="activeName">

          <el-tab-pane label="草业统计 " name="first">
          <Content></Content>
          </el-tab-pane>
          <el-tab-pane label="饲料证书查询 " name="second">
            <Feedcertificate></Feedcertificate>
          </el-tab-pane>
          <!-- <el-tab-pane label="天然饲草利用 " name="first"
            ><NaturalGrass
          /></el-tab-pane>
          <el-tab-pane label="饲草种植 " name="second">
            <GrassSeed
          /></el-tab-pane>
          <el-tab-pane label="饲草种子生产 " name="third"
            ><ForageSeed
          /></el-tab-pane>
          <el-tab-pane label="草产品加工企业生产" name="fourth"
            ><GrassProcessing
          /></el-tab-pane>
          <el-tab-pane label="农闲田种草" name="fifth"
            ><Assembly
          /></el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <div class="mainContainer">
      <!-- <Assembly/> -->
      <!-- <AssemblyTwo/> -->
    </div>
  </div>
</template>
<script>
import ForageSeed from "./ForageSeed.vue";
import GrassProcessing from "./GrassProcessing.vue";
import Top from "../../components/top/index.vue";
import Assembly from "./assembly.vue";
import AssemblyTwo from "./assemblyTwo.vue";
import GrassSeed from "./GrassSeed.vue";
import Seedproduction from "./seedproduction.vue";
import Feedcertificate from "./components/feedcertificate.vue";
import Content from "./content.vue"
export default {
  components: {
    ForageSeed,
    Top,
    Assembly,
    AssemblyTwo,
    GrassProcessing,
    GrassSeed,
    Feedcertificate,
    Seedproduction,
    Content
  },
  data() {
    return {
      activeName:this.$route.query.activeName||localStorage.getItem("activeName"),
      show:1
    };
  },
  mounted(){
        let activeName = this.$route.query.activeName;
    localStorage.setItem("activeName", activeName);
      this.handleSelect()
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    one(){
      this.show=1
    },
    two(){
      this.show=2
    },
    three(){
       this.show=3
    },
    four(){
       this.show=4
    },
    five(){
       this.show=5
    }
  },
};
</script>

<style scoped>
.login_top {
  height: 160px;
  width: 100%;
  background-image: url(../../assets/头部底图.png);
  background-size: 100% 100%;
}
.logintop_min {
  width: 1200px;
  margin: auto;
}
.min_top {
  height: 160px;
  line-height: 160px;
  display: flex;
}
.top_img1 {
  display: flex;
  align-items: center;
}
.top_img2 {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 20px;
}
.gerenone {
  /* width: 222px; */
  height: 40px;
  display: flex;
  background-color: #fff;
  margin-top: 110px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.8;
}
.gerenone img {
  width: 20px;
  height: 20px;
  padding: 0 10px;
}
.grass_box_title {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;

  width: 1200px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 20px;
}
.grass_box {
  /* width: 1200px; */
  margin: auto;
}
.mainContainer {
  width: 1200px;
  margin: 0 auto;
}
::v-deep .el-tabs__item {
  line-height: 30px;
}
::v-deep .el-tabs__item {
  /* width: 200px; */
  font-size: 20px;
  font-family: MicrosoftYaHei;
  color: #333333;
  padding: 0 70px;
}
::v-deep .el-tabs__header {
  width: 1200px;
  margin: auto;
}
::v-deep .el-tabs__nav {
  width: 100%;
  display: flex;
}
::v-deep .el-tabs__item {
  width: 50%;
  text-align: center;
  height: 60px;
  /* padding: 10px 0; */
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #dddddd;
  padding: 0 !important;
}
::v-deep .el-tabs__active-bar {
  top: 0;
}
.grass_min_box {
  width: 1200px;
  margin: auto;
  display: flex;
    margin-top: 40px;
}
.grass_nav {
  width: 212px;
  margin-right: 88px;
}
.grass_nav_one {
  border-radius: 4px;
  border: 1px solid #dddddd;
  height: 64px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
}
.grass_right {
  flex: 1;
}
.active{
  background: #E9F4FF;
border-radius: 4px;
border: 1px solid #3275D6;
font-size: 18px;
font-family: MicrosoftYaHei;
color: #3275D6;
}
</style>
