<template>
  <div class="contentWrap">
    <div class="contentWrap_min">
      <div class="ass_header">
      <div class="ass_content">
        <div class="item1">
          <div class="title">当年青贮量</div>
          <div class="desc"><span>3461</span><span>万吨</span></div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="title">打井数量</div>
          <div class="desc"><span>1034</span><span>个</span></div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="title">草场灌溉面积</div>
          <div class="desc"><span>4361</span><span>万亩</span></div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="title">井灌面积</div>
          <div class="desc"><span>5761</span><span>万亩</span></div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="title">定居点牲畜棚圈面积</div>
          <div class="desc"><span>1240</span><span>平方米</span></div>
        </div>
      </div>
    </div>
    <div class="contentMain">
        <EchartTwo/>
    </div>
    <div>
      <Feed></Feed>
    </div>
    </div>
  </div>
</template>

<script>
import EchartTwo from "./components/echartTwo.vue";
import Feed from "./components/feedcertificate.vue"
export default {
  name: "",
  components: {
    EchartTwo,
    Feed
  },
};
</script>

<style lang="less" scoped>
.contentWrap{
  background: #F6F9FC;
}
.contentWrap_min{
  width: 1200px;
  margin: auto;
}
.ass_content {
  display: flex;
  align-items: center;
}
.item1 {
  width: 212px;
  margin-right: 20px;
  padding: 30px 0 15px 30px;
}
.line {
  width: 1px;
  height: 62px;
  background: #e1e1e1;
  margin: 20px 10px;
}
.title {
  margin-bottom: 18px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
.desc span:nth-child(1) {
  font-size: 26px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
}
.desc span:nth-child(2) {
  display: inline-block;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #999999;
  margin-left: 10px;
}

</style>
